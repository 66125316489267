@import 'shared/master.scss';

.UCSFBulkReportLauncher {
  position: relative;
  @page {
    size: letter;
  }

  .error {
    margin-top: 10px;
    color: red;
  }
  h2.error {
    margin-top: 20px;
    font-size: 36px;
  }

  .report-with-pagebreak {
    // Stuff to try to get browser in Apex to handle page breaks well
    float: none;
    overflow: hidden;
  }

  .pagebreak { 
    page-break-after: always;
  } 

  @media print {
    width: 9.5in;
    .pagebreak { 
      page-break-after: always !important;
    } 
  }
}