@import 'shared/master.scss';

.BulkReportInput {
  h1 {
    font-weight: bold;
    font-size: 36px;
  }

  h2 {
    font-weight: bold;
    font-size: 24px;
  }

  .header {
    background-color: $background-grey;
    height: 80px;
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .data-entry {
    padding: 30px 20px 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  textarea {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  button {
    width: 350px;
    font-size: 28px;
    background-color: $bright-blue;
    color: white;
    height: 100%;
    font-weight: normal;
    padding: 20px 30px;
    border-radius: 50px;
    align-self: center;
  }

}