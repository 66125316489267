@import 'shared/master.scss';

.BPACSV {
  border-bottom: 2px solid black;
  padding: 20px;

  .buttons {
    display: flex;
    justify-content: space-between;
    button {
      background-color: $bright-blue;
      padding: 10px;
      color: white;
      font-weight: bold;
      font-size: 28px;
      border-radius: 10px;
       width: fit-content;
    }
  }
  @media print {
    display: none;
  }

  .download {
    background-color: $bright-blue;
    padding: 10px;
    color: white;
    font-weight: bold;
    font-size: 28px;
    border-radius: 10px;
    width: fit-content;
  }
}