@import 'shared/master.scss';

$selected-text: #696c6d;
$warning-red: #D81646;
$greyed-out-blue: #ABC3E1;

#BP {
  padding: 20px 0;
  background-color: white;

  .subheader {
    font-weight: bold;
    font-size: 20px;
  }

  .subsubheader {
    font-weight: bold;
    font-size: 16px;
    color: $bright-blue;
  }

  .summary {
    padding-bottom: 30px;

    table {
      margin-top: 20px;
      width: 100%;
    }
  
    thead {
      background-color: $border-blue;
    }
  
    th {
      font-weight: bold;
      border: 1px solid $border-grey;
      padding: 5px;
      text-align: left;
    }
  
    td {
      border: 1px solid $border-grey;
      padding: 5px;
      font-size: 16px;
      color: $text-grey;
    }

    .clinic {
      margin-bottom: 20px;
    }

    select {
      margin: 15px 0 10px 12px;
      font-size: 16px;
      width: 170px;
      border-color: #006BE9;
      border-radius: 5px;
      padding: 6px;
    }

    .subsubheader {
      margin: 10px 12px 5px;
      font-weight: bold;
      color: $header-black;
    }

    p {
      font-size: 16px;
      line-height: 30px;
      color: black;
    }

    p.data {
      font-size: 12px;
      line-height: 14px;
    }

    .columns {
      display: flex;
      flex-direction: row;
      margin-left: 0;
    }

    .column {
      border-right: 1px solid $border-grey;
      margin-left: 12px;
    }

    .column.last {
      border-right: none;
    }
  }

  .summary.form {
    margin-top: 30px;
    padding-bottom: 10px;
    margin-bottom: 0;
  }

  .data-entry {
    display: flex;
    flex-direction: row;
  }

  .chart {
    margin-bottom: 25px;
  }

  form {
    padding: 12px 20px;
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .form-row {
      //align-self: stretch;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 5px 0;

      .react-date-picker {
        margin-left: 8px;
        width: 80px;

      }
    }

    .bp-input {
      width: 50px;
      border-width: 1px;
    }

    input {
      text-align: left;
      max-width: 120px;
      border-width: 1px;
      margin-left: 8px;
    }

    .subheader {
      margin-top: 0;
    }

    .submit {
      width: initial;
      align-self: flex-end;;
      background-color: $bright-blue;
      color: white;
      font-size: 20px;
      text-align: center;
      border: none;
      border-radius: 20px;
      padding: 4px 15px;
      cursor: pointer;
    }

    .submit.disabled {
      background-color: $greyed-out-blue;
      cursor: default;
    }
  }

  form.individual_homeBp {
    margin-right: 25px;
  }

  form.average_homeBp {
    margin-left: 25px;
  }

  p.warning {
    margin: 15px 0 0 0;
    color: $warning-red;
    text-align: center;
  }

  .linechart {
    margin-left: -20px;
    width: 100%;
    height: 300px;
  }

  .buttons {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      margin-right: 10px;
      font-size: 20px;
      background-color: #D5D9DE;
      color: $selected-text;
      height: 100%;
      font-weight: normal;
      padding: 5px;
      border-radius: 5px;
    }
    button.week {
      margin-right: 20px;
    }
    
    button.selected {
      background-color: $bright-blue;
      color: white;
    }
  }

  .source.buttons {
    margin: 20px 0 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    button {
      margin-right: 0;
      padding: 5px 12px;
    }

    button.clinic {
      border-radius: 20px 0 0 20px;
      border-right: 1px solid #2455D7;
    }

    button.home {
      border-radius: 0 20px 20px 0;
      border-left: 1px solid #2455D7;
    }
  }

  .one-row {
    display: flex;
  }

  .tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: $tooltip-green;
    font-size: 16px;
    font-weight: bold;
    width: 20px;
    height: 20px;
    margin-left: 15px;
  }

  .tooltip-extra-class {
    font-size: 16px !important;
    font-weight: bold;
    color: $header-black;
    border-radius: 20px !important;
    pointer-events: auto !important;
    background-color: $tooltip-green;
    opacity: 1.0;
  }
}