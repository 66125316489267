@import 'shared/master.scss';

#SubmitFeedback {
  display: flex;
  flex-direction: column;

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  label {
    font-size: 24px;
    font-weight: bold;
    margin: 10px 0;
  }

  textarea {
    margin: 10px 0;
    font-size: 18px;
  }

  button {
    //margin-left: 20px;
    padding: 0px 15px;
    height: 30px;
    border-radius: 20px;
    background-color: $bright-blue;
    font-size: 16px;
    font-weight: bold;
    color: white;
    vertical-align: center;
    margin: auto;
  }

  button.cancel {
    background-color: white;
    color: $header-black;
    border: 2px solid $border-grey
  }
}