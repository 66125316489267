@import 'shared/master.scss';

#Labs {
  background-color: white;

  table {
    margin-top: 20px;
    width: 100%;
  }

  thead {
    background-color: $border-blue;
  }

  th {
    font-weight: bold;
    border: 1px solid $border-grey;
    padding: 5px;
    text-align: left;
  }

  td {
    border: 1px solid $border-grey;
    padding: 5px;
    font-size: 16px;
    color: $text-grey;
  }
  td.out-of-range {
    color: red;
  }
}