@import 'shared/master.scss';

#BPGoal {
  padding: 5px;
  z-index: 2;
  position: absolute;
  border: 1px solid lightgrey;
  border-radius: 8px;
  top: 35px;
  left: 0;
  width: 190px;
  background-color: white;

  .radio-button {
    padding: 5px;
    border-bottom: 1px solid lightgrey;
    cursor: pointer;

    input {
      cursor: pointer;
    }
    label {
      cursor: pointer;
    }
  }

  .manual {
    margin-top: 8px;
    margin-left: 8px;
    display: flex;
    flex-direction: row;

    input {
      width: 45px;
      margin: 0 4px;
    }

  }
  .section {
    margin-bottom: 5px;
  }

  .add-new {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    button {
      align-self: flex-end;
      margin-top: 8px;
      width: 80px;
      font-size: 20px;
      padding: 5px 8px;
    }

  }
}