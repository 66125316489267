.Home {
  padding: 25px;
  h1 {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  p {
    font-size: 24px;
    line-height: 36px;
  }

  a {
    font-size: 24px;
    line-height: 36px;
    text-decoration: underline;
    color: blue;

  }
}

