@import 'shared/master.scss';

.BPACheckbox {
  margin: 10px 0;
  display: flex;
  flex-direction: row;

  .box {
    width: 20px;
    min-width: 20px;
    height: 20px;
    border: 2px solid black;
    margin-right: 8px;
  }
}