@import 'shared/master.scss';

.BulkReportProgress {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  height: 200px;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  border-radius: 5px;
  border: 2px solid grey;
  background-color: #f8f8f8;

  h1 {
    font-size: 24px;
    font-weight: bold;
    color: black;
  }

}