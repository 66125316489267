@import 'shared/master.scss';

#Checkbox {


  .cbox-container {
    display: block;
    position: relative;
    padding: 15px 0 8px 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    border-top: 1px solid $border-grey;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .text {
      margin-left: 5px;
    }

    .text.bold {
      font-weight: bold;
      font-size: 18px;
    }
  }

  .cbox-container.no-border {
    border: none;
  }

  /* Hide the browser's default checkbox */
  .cbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 10px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #006BE9;
  }
  
  /* When the checkbox is checked, add a blue background */
  .cbox-container input:checked ~ .checkmark {
    background-color: #006BE9;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .cbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .cbox-container .checkmark:after {
    left: 6px;
    top: 0px;
    width: 5px;
    height: 16px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}