@import 'shared/master.scss';

@media print {
  //Recalculate width in print mode to fix the bug the graph isn't responsive when print.
  .recharts-wrapper, .recharts-surface, .recharts-legend-wrapper {
      width: 100%!important;
  }
  //Prevent recharts-legend-item have space between <svg> icon and <span> due to recalculate width: 100%
  //try width: fit-content not work on safari
  .recharts-legend-item > svg{
      width: auto!important;
      display: inline-block!important;
      right:inherit!important; /* align to left */
  }
}

.BPChart {
  margin-left: -20px;
  height: 300px;

  // @media print {
  //   width: 6in !important;
  //   .recharts-responsive-container {
  //     width: 67% !important;
  //   }
  // }
}