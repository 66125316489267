@import 'shared/master.scss';

#Rationale {
  padding: 0 0 0;
  background-color: white;

  .section {
    padding-top: 20px;
    border-top: 1px solid $border-grey;
  }

  li {
    line-height: 30px;
    color: $text-grey;
  }
  
  .section.first {
    border-top: none;
  }

  .section-header {
    margin: 5px 0 10px;
    font-weight: bold;
    font-size: 16px;
  }

  .tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: $tooltip-green;
    font-size: 16px;
    font-weight: bold;
    width: 20px;
    height: 20px;
    margin-left: 15px;
  }

  .tooltip-extra-class {
    font-size: 16px !important;
    font-weight: bold;
    color: $header-black;
    border-radius: 20px !important;
    pointer-events: auto !important;
    background-color: $tooltip-green;
    opacity: 1.0;
  }

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}