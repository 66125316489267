@import './shared/master.scss';

.EngageRxLoading {
  padding: 20px;

  p {
    color: $header-black;
    font-size: 30px;
    font-weight: bold;

  }
  img {
    height: 25px;
    margin-left: 10px;
  }
  ul {
    margin-top:20px
  }
  li {
    font-size: 20px;
    line-height: 25px;
    color: $text-grey;
    display: flex;
    align-items: center;
  }
}