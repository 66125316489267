@import 'shared/master.scss';

#Medications {
  padding: 20px 0 0;
  background-color: white;

  ul {
    list-style: none;
    margin-left: 0;
    margin-top: 15px;
    font-size: 16px;
    font-weight: normal;
    color: $text-grey;
  }

  li {
    line-height: 30px;
  }

  .medication-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .subheader {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: $header-black;
  }

}