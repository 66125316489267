@import 'shared/master.scss';

#Header {
  .background-row {
    background-color: $background-grey;
    color: white;
    font-weight: bold;
    padding: 20px 50px;
    justify-content: space-between;
    display: flex;

    .info-row-1 {
      font-size: 28px;
    }

    .info-row-2 {
      margin-top: 15px;
      font-size: 16px;

      .value {
        margin-left: 10px;
        font-weight: lighter;
      }

      .label {
        margin-left: 40px;
      }

      #patient-dob.label {
        margin-left: 0;
      }
    }
  }
  .right {
    align-self: center;

    p {
      margin-top: 10px;
      margin-left: 10px;
    }

    button {
      margin-right: 20px;
      padding: 8px 15px;
      height: 40px;
      border-radius: 20px;
      background-color: white;
      font-size: 16px;
      font-weight: bold;
      color: #052049;
    }
  }
}