@import 'shared/master.scss';

#Patient {

  background-color: $background-light-grey;
  padding-bottom: 30px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .columns {
    display: flex;
    flex-direction: column;

    @media (min-width: 767px)  {
      flex-direction: row;
      justify-content: flex-start;
    }

    .column {
      max-width: 640px;
      width: 100%;
    }

    .column.left {
      margin-right: 5px;
    }
  }

  .recommendation {
    max-width: 450px;
    margin-top: 8px;
    cursor: pointer;
    color: #0000EE;

    ul {
      margin-left: 0px;
    }

    li {
      margin-top: 8px;
      margin-left: 36px;
      text-decoration: underline;
    }
  }

  li {
    line-height: 16px;
    margin-bottom: 3px;
  }

  .loading {
    margin-top: 50px;
  }

  h1 {
    font-size: 28px;
    font-weight: bold;
  }

  h2 {
    font-size: 20px;
    font-weight: bold;
  }

  h3 {
    font-size: 16px;
    font-weight: bold;
  }

  .adherence {
    label {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }

    input {
      width: unset;
      margin-right: 10px;
    }
  }

  .rationale {
    margin-top: 20px;
  }

  .medications {
    margin-top: 20px;
  }

  .bp {
    margin-top: 20px;
  }

  .labs {
    margin-top: 20px;
  }

  .recommendations {
    margin-top: 20px;

    ol {
      list-style: upper-alpha;

      ol {
        list-style: lower-roman;
        li {
          margin-left: 20px;
        }
      }
    }
    .preface {
      margin-bottom: 8px;
    }

    .document {
      margin-top: 8px;
      cursor: pointer;
      text-decoration: underline;
    }

    .first-options {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
    .first-options.below {
      flex-direction: column;
    }

    .hidden {
      display: none;
    }

    .below {
      button {
        margin-top: 0px;
      }
    }
  }

  button {
    height: 22px;
    margin-top: 4px;
    padding: 0 5px;
    text-decoration: none;
    cursor: pointer;
  }

  .label {
    font-weight: bold;
  }

  .section {
    margin-left: px;
    margin-bottom: 10px;
  }
}