@import './master.scss';

.Expander {
  .maintitle {
    margin-top: 10px;
    border: 1px solid $border-grey;
    border-radius: 12px;
    padding: 20px;
    background-color: white;
  }

  .maintitle.no-border {
    border: none;
    padding: 10px;
  }

  .subtitle {
    border: none;
    padding: 5px 0;
  }

  .title-bar {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    //justify-content: space-between;
    cursor: pointer;
    color: $text-green;
  }

  .title-bar.right-caret {
    justify-content: space-between;
  }

  .caret {
    margin-left: 10px;
  }

  .subtitle .title-bar h4 {
    color: $bright-blue;
    font-size: 16px;
    justify-content: flex-start;
  }

  .subtitle .title {
    margin-right: 20px;
  }

  .content {
    border-top: 1px solid $border-grey;
    margin-top: 20px;
  }
}