@import 'shared/master.scss';

#Recommendations {
  border: 1px solid $border-grey;
  border-radius: 12px;
  background-color: white;
  color: $text-grey;

  .explanation {
    list-style: none;
    color: $text-grey;
  }

  .header {
    background-color: $background-grey;
    color: white;
    font-weight: bold;
    padding: 20px 50px;
    font-size: 28pxr;
    text-align: center;
    display: block;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .reco-title {
    font-size: 20px;
    font-weight: bold;
    color: $text-green;
    margin-bottom: 10px;
  }

  .reco-title-bar {
    margin-top: 20px;
    margin-left: 40px;
    padding-right: 5px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-weight: bold;
    cursor: pointer;

    .bp-goal {
      position: relative;
      margin-top: -5px;
      margin-left: auto;
      display: flex;
      padding: 0 5px; 
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border: 2px solid $bright-blue;
      border-radius: 5px;
      font-weight: normal;
      max-height: 35px;
      box-sizing: border-box;

      button.caret {
        margin: 0 0 0 10px;
        width: 16px;
        height: 16px;
        padding: 0;
        border-radius: 0;
        background-color: lightgrey;
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid grey;

        .label {
          font-size: 18px;  
          font-weight: bold;
        }
      }
    }
  }

  p.preface {
    font-weight: normal;
    line-height: 22px;
    padding-bottom: 5px;
  }

  .main {
    margin-bottom: 10px;
    font-weight: normal;
    font-size: 16px;
    color: $text-grey;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    line-height: 18px;
  }
  
  .tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: $tooltip-green;
    font-size: 16px;
    width: 20px;
    height: 20px;
    margin-left: 15px;
  }

  .tooltip-extra-class {
    font-size: 16px !important;
    font-weight: bold;
    color: $header-black;
    border-radius: 20px !important;
    pointer-events: auto !important;
    background-color: $tooltip-green;
    opacity: 1.0;

    ul {
      list-style-type: disc;
      list-style-position: inside;
    }

    a {
       font-size: 14px;
    }

    .references {
      font-size: 14px;
    }
  }

  .or {
    margin: 5px 0 15px 40px;
    color: $header-black;
  }

  .show-more {
    font-size: 16px;
    color: $bright-blue;
    margin-bottom: 15px;
  }

  .container {
    position: relative;
    font-size: 16px;
    line-height: 20px;
    color: $text-grey;
    margin: 0.25rem;

    .letter {
      margin-right: 5px;
    }

    label {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    input[type="radio"] {
      position: absolute;
      opacity: 0;
      + .radio-label {
        &:before {
          content: '';
          // background: $bright-blue;
          background: white;
          border-radius: 100%;
          border: 1px solid $bright-blue;
          display: inline-block;
          min-width: 1.4em;
          width: 1.4em;
          height: 1.4em;
          position: relative;
          top: -0.1em;
          margin-right: 0.5em; 
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }
      &:checked {
        + .radio-label {
          &:before {
            background: $bright-blue;
            box-shadow: inset 0 0 0 4px white;
          }
        }
      }
      + .radio-label {
        max-width: 450px;
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }

  // .with-textbox {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   input {
  //     cursor: auto;
  //     height: 20px;
  //     width: 100px
  //   }
  // }

  p {
    font-size: 16px;
    font-weight: bold;
    color: black;
  }
  
  .reco-list {
    margin: 10px 20px 0 40px;
    padding-bottom: 20px;
    border-bottom: 4px solid $border-grey;
  }

  .checkbox-list {
    margin: 20px 40px;

    ul {
      list-style-position: inside;
    }

    ol {
      list-style-position: inside;
      list-style-type: upper-alpha;
    }

    p {
      color: $text-grey;
      font-weight: normal
    }

    a {
      color: #006BE9;
    }

    li {
      font-size: 16px;
      line-height: 20px;
      color: $text-grey;
      margin-top: 14px;
      margin-bottom: 8px;
    }

    li.too-tall {
      margin-top: 0px;
    }

    .dash {
      ul {
        list-style: inside;
      }

      li {
        margin-top: 5px;
        margin-bottom: 5px;
      }
      li.sub {
        list-style-type: circle;
        margin-left: 10px;
      }

      p {
        margin-top: 8px;
      }

      .highlight {
        font-weight: bold;
        margin-right: 5px;
      }
    }
    
    h3 {
      margin-top: 15px;
      margin-bottom: 0px;
      text-decoration: underline;
      font-weight: normal;
    }

    h3.top {
      margin-top: 0;
    }
  }

  .button-container {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  button {
    width: 350px;
    font-size: 28px;
    background-color: $bright-blue;
    color: white;
    height: 100%;
    font-weight: normal;
    padding: 20px 30px;
    border-radius: 50px;
  }
}