@import 'shared/master.scss';

.BPActivateReport {
  box-sizing: border-box;
  padding: 10px 15px;
  float: none !important;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 67% 34%;
  grid-row-gap: 10px;
  grid-column-gap: 15px;
  width: 100%;

  .report-name {
    grid-column: 1;
    grid-row: 1;
  }

  .report-patient {
    grid-column: 2;
    grid-row: 1;
  }

  .section {
    margin-top: 10px;
  }

  .bp-header {
    grid-column: 1;
    grid-row: 2;
  }

  .bp-chart-and-title {
    grid-column: 1;
    grid-row: 3;
    width: 100%;
  }

  .bp-stats {
    grid-column: 2;
    grid-row: 3;
  }

  .med-header {
    grid-column: 1;
    grid-row: 4;
  }

  .med-list {
    //margin-top: 5px;
    grid-column: 1;
    grid-row: 5;
  }

  .med-confirmation {
    grid-column: 2;
    grid-row: 5;
  }

  .recommendations-header {
    grid-column: 1;
    grid-row: 6;
  }

  .recommendations-list {
    grid-column: 1;
    grid-row: 7;
  }

  .recommendations-next {
    grid-column: 2;
    grid-row: 6 / 8;
  }

  .recommendations-notes {
    grid-column: 1 / 3;
    grid-row: 8;
  }


  .adjacent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  h1 {
    font-size: 28px;
    font-weight: bold;
  }

  h2 {
    font-size: 26px;
    font-weight: bold;
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
  }

  p {
    font-size: 20px;
    line-height: 25px;
  }

  .left-spaced {
    margin-left: 10px;
  }

  .top-spaced {
    margin-top: 10px;
  }

  .double-top-spaced {
    margin-top: 25px;
  }

  img {
    height: 20px;
  }

  p.orange {
    font-weight: bold;
    color: #FF824A;
  }
  table {
    width: 95%;
    font-size: 18px;

    tr {
      border-bottom: 1px solid grey;
      height: 1.5em;
    }
    th {
      font-weight: bold;
      text-align: left;
    }
    td {
      padding-right: 10px;
      vertical-align: middle;
    }
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
    li {
      margin-top: 4px;
    }
    ol {
      list-style-type: lower-alpha;
      margin-left: 10px;
    }
  }

  .grey {
    border-radius: 5px;
    padding: 15px;
    background-color: #f5f5f5
  }
}