@import 'shared/master.scss';

.OtherSuggestions {

  .DropDownBox {
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .box-spacer {
    //margin-bottom: 5px;
  }

  .header {
    background-color: $background-grey;
    color: white;
    font-weight: bold;
    padding: 20px 50px;
    font-size: 28pxr;
    text-align: center;
    display: block;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .reco-title {
    font-size: 20px;
    font-weight: bold;
    color: $text-green;
    margin-bottom: 10px;
  }

  .reco-title-bar {
    margin-top: 20px;
    margin-left: 40px;
    padding-right: 5px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-weight: bold;
    cursor: pointer;
  }

  .main {
    margin-bottom: 10px;
    font-weight: normal;
    font-size: 16px;
    color: $text-grey;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    line-height: 18px;
  }
  
  .with-textbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: -5px;
    input {
      cursor: auto;
      height: 20px;
      width: 100px;
      padding: 0;
      margin: 0;
    }
  }

  p {
    font-size: 16px;
    font-weight: bold;
    color: black;
  }
  
  .reco-list {
    margin: 10px 20px 0 40px;
    padding-bottom: 20px;
    border-bottom: 4px solid $border-grey;
  }

  .checkbox-list {
    margin-top: 20px;
    //margin: 20px 40px;

    ul {
      list-style-position: inside;
    }

    ol {
      list-style-position: inside;
      list-style-type: upper-alpha;
    }

    p {
      color: $text-grey;
      font-weight: normal
    }

    a {
      color: #006BE9;
    }

    li {
      font-size: 16px;
      line-height: 20px;
      color: $text-grey;
      margin-top: 14px;
      margin-bottom: 8px;
    }

    li.too-tall {
      margin-top: 0px;
    }

    .dash {
      ul {
        list-style: inside;
      }

      li {
        margin-top: 5px;
        margin-bottom: 5px;
      }
      li.sub {
        list-style-type: circle;
        margin-left: 10px;
      }

      p {
        margin-top: 8px;
      }

      .highlight {
        font-weight: bold;
        margin-right: 5px;
      }
    }
    
    h3 {
      margin-top: 15px;
      margin-bottom: 0px;
      text-decoration: underline;
      font-weight: normal;
    }

    h3.top {
      margin-top: 0;
    }
  }
}