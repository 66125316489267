@import 'shared/master.scss';

.DropDownBox {
  display: flex;
  align-items: center;
  cursor: pointer;

  .dbox-container {
    border: 1px solid #006BE9;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  .caret {
    margin: 0;
  }
}