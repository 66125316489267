$background-grey: #506380;
$background-light-grey: #F2F4F6;
$bright-blue: #006BE9;
$text-grey: #052049;
$border-blue: #D7DEF2;
$border-grey: #D5D9DE;
$header-black: #052049;
$text-green: #1a8917;
$tooltip-green: #d3e4ad;
$bpa-highlight-yellow: #F8CC24;

$crx-dark-blue: #506280;
$crx-light-grey: #F3F3F3;
$crx-green : #3D6F49;
$crx-link-blue : #0066EC;
$crx-header-grey: #515151;
$crx-unselected-grey: #C4D5EB;

@mixin subsection-title {
  color: $crx-green;
  font-size: 14px;
  font-weight: bold;
};

@mixin subsection-content {
  color: $crx-dark-blue;
  font-size: 14px;
};

@mixin crx-button {
  display: flex;
  width: 155px;
  height: 55.457px;
  flex-direction: column;
  justify-content: center;
  background-color: $crx-link-blue;
  color: white;
  border-radius: 40px;
}